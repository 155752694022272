import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { BOGS_ACCESS_TOKEN, BOGS_REFRESH_TOKEN } from '../contants/token';
import { setTokenToLocalStorage } from '../utils/authenticate';
import { baseURL } from "../contants/baseUrl";
import router from "../routes/Router";

const axiosInstance = axios.create({
    baseURL: baseURL,
		headers: {
			"ngrok-skip-browser-warning": true,
		},
});

axiosInstance.interceptors.request.use(
  async function (config) {
    try {
      const token = localStorage.getItem(BOGS_ACCESS_TOKEN);
      if (!token) window.location.href('/login')
      const decoded = jwtDecode(token);
      if (
        decoded &&
        decoded.exp * 1000 <= Date.now()
      ) {
        const refreshToken = localStorage.getItem(BOGS_REFRESH_TOKEN);
        const newToken = await axios.post(
          '/auth/token/refresh',
          { refreshToken }
        );
        setTokenToLocalStorage(
          newToken.data.data.accessToken,
          newToken.data.data.refreshToken
        );
        config.headers.Authorization = `Bearer ${newToken.data.data.accessToken}`;
        return config;
      }

      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } catch (error) {
      localStorage.removeItem(BOGS_ACCESS_TOKEN);
      localStorage.removeItem(BOGS_REFRESH_TOKEN);
      window.location.href = '/';
      console.error(error);
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem(BOGS_ACCESS_TOKEN);
      localStorage.removeItem(BOGS_REFRESH_TOKEN);
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
